import { apolloClient } from "@/api/apolloClient";
import {CREATE_CAMPAIGN, UPDATE_CAMPAIGN, DELETE_CAMPAIGN, SET_CAMPAIGN_PRIORITY} from "../api/graphql/mutation/CampaignMutation";
import { CAMPAIGNS_SHORT_LIST_ADMIN_FILTERED } from "../api/graphql/query/CampaignQuery";

export default {
    createCampaign: function(campaign, travelTips){
        const {validFrom, validTo, state, localizations, originalLanguage} = campaign;
        return apolloClient.mutate({
            mutation: CREATE_CAMPAIGN,
            variables: {
                validFrom,
                validTo,
                state,
                travelTips,
                originalLanguage,
                localizations

            },
            update: (store,{data: {createCampaign}}) => {
                // Add to all campaigns detail list
                let data = store.readQuery({
                    query: CAMPAIGNS_SHORT_LIST_ADMIN_FILTERED,
                    variables: {filter: {searchString: "", state: null, countries: []}}

                });
                console.log(data)
                if (data) {
                    data = {
                      ...data,
                      filteredCampaigns: [
                        ...data.filteredCampaigns,
                        createCampaign
                      ],
                    }
                    store.writeQuery({
                        query: CAMPAIGNS_SHORT_LIST_ADMIN_FILTERED,
                        variables: {filter: {searchString: "", state: null, countries: []}},
                        data
                    });
                }
            }
        }).then((result) => {
            console.log("Campaign created: ", result);
            return result;
        });
    },

    updateCampaign: function(id, campaign, travelTips){
        console.log("Updating campaign with id : " + id);
        const {validFrom, validTo, state, localizations, originalLanguage} = campaign;

        return apolloClient.mutate({
            mutation: UPDATE_CAMPAIGN,
            variables: {
                id,
                validFrom,
                validTo,
                state,
                travelTips,
                originalLanguage,
                localizations
            },
        }).then((result) => {
            console.log("Campaign updated: ", result);
            return result;
        });
    },

    deleteCampaign: function(campaignId) {
        console.log("Start to delete campaign with id: " + campaignId);
        return apolloClient.mutate({
            mutation: DELETE_CAMPAIGN,
            variables: {
                campaignId
            },
        }).then((result) => {
            console.log("Campaign deleted: ", result);
            return result;
        });
    },

    setPriority: function(campaignId, priorityOrder) {
        console.log("Start topping Campaign with ID: " + campaignId);
        return apolloClient.mutate({
            mutation: SET_CAMPAIGN_PRIORITY,
            variables: {
                campaignId,
                priorityOrder
            },
        }).then((result) => {
            console.log("Campaign Topped: ", result);
            return result;
        });
    },
}
