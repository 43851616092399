import gql from "graphql-tag"

export const CREATE_CAMPAIGN = gql`
    mutation createCampaign(
        $validFrom: Date!, 
        $validTo: Date!,
        $state: String!
        $travelTips: [ID]
        $originalLanguage: String!,
        $localizations: [CampaignLocalizationInput]

    ) {
        createCampaign(
            validFrom: $validFrom,
            validTo: $validTo,
            state: $state
            travelTips: $travelTips,
            originalLanguage: $originalLanguage,
            localizations: $localizations
        ) {        
            id,
            validFrom,
            validTo,
            state,
            priorityOrder,
            localizations {
                title,
                description
            }
            travelTips {
                campaignId,
                id,
                hashtags,
                titlePicture {
                    id
                    fileName
                },
                theme,
                dayCount,
                localizations {
                    title
                    perex
                    description
                }
                itineraryDays {
                    id,
                    itineraryItems {
                        countries {
                            code
                            nameCzech
                        }
                        id
                    }
                }
            },
            author {
                id
                userName
                avatarFileName
                __typename
            },
            __typename
        }
    }`

export const UPDATE_CAMPAIGN = gql`
    mutation updateCampaign(
        $id:Int!,
        $validFrom: Date!, 
        $validTo: Date!,
        $state: String!,
        $travelTips: [ID],
        $originalLanguage: String!,
        $localizations: [CampaignLocalizationInput]
    ) {
        updateCampaign(
            id:$id,
            validFrom: $validFrom,
            validTo: $validTo,
            state: $state,
            travelTips: $travelTips,
            originalLanguage: $originalLanguage,
            localizations: $localizations
        )   {        
            id,
        }
    }`

export const DELETE_CAMPAIGN = gql`
    mutation deleteCampaign($campaignId: Int!) {
        deleteCampaign(campaignId: $campaignId)
    }`

export const SET_CAMPAIGN_PRIORITY = gql`
    mutation updateCampaignPriorityOrder($campaignId:Int!, $priorityOrder:Int!) {
        updateCampaignPriorityOrder(campaignId: $campaignId, priorityOrder: $priorityOrder) {
            id, 
            priorityOrder
        }
    }`