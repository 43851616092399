<template>
  <div style="padding-bottom: 80px;">
    <div v-if="campaignId && initialLoading" class="admin-page-loading">{{ $t("loading.loading") }}</div>
    <div v-show="!campaignId || !initialLoading" class='container-fluid text-left'>
      <div class="d-flex justify-content-between mb-4">
        <h1 class='campaigns-title'><span class="arrow arrow--left" @click='$router.go(-1)'></span>{{$route.params.campaignId ? form.localizations[0] && form.localizations[0].title : $t("admin.addCampaign")}}</h1>
        <div class="buttons-localizations">
          <TrekioButton v-if="form.localizations.length > 1" secondary @click="toggleManageLocalizations">{{ isManagingLocalizations ? $t("buttons.hideTranslations") : $t("buttons.showTranslations") + ` (${translatedLanguages.length})`}}</TrekioButton>
          <TrekioButton v-if="$route.params.campaignId && languagesToBeTranslated.length" primary @click="showTranslationOptionsModal">{{ $t("buttons.createTranslation") }}</TrekioButton>
        </div>
      </div>
      <b-modal size="fit-content" body-class="p-0" class="modal" id="create-language-translation-modal" hide-footer hide-header>
        <div class="language-option" v-for="language in languagesToBeTranslated" :key="language.code" @click="createTranslation(language)">{{language.nameEnglish}}</div>
      </b-modal>

      <UnsavedChangesModal :isModalVisible="isUnsavedChangesModalVisible" @hideModal="isUnsavedChangesModalVisible = false" @discardChangesAndContinue="discardChangesAndContinue"/>
      <UnpublishOtherLocalizationsModal :isModalVisible="isUnpublishOtherLocalizationsModalVisible" @saveAndUnpublishOthers="saveAndUnpublishOthers" @save="onSubmit(true)" />
        
        <b-alert :show="alert.dismissCountDown" fade id="alert"
                 class="alert-success">{{alert.notification}}
        </b-alert>
        <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger" style="padding-right: 45px"
                  @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">{{alertDanger.notification}}
               <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
        </b-alert>
        <a class="basic-info" v-b-toggle.collapseBasicInfo>{{ $t("general.basicInfo") }}</a>
        <b-collapse id="collapseBasicInfo" visible style="padding-top: 30px">

          <LocalizationSelects style="margin-bottom: 30px;" :isManagingLocalizations="isManagingLocalizations" :originalLanguage="form.originalLanguage" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :languages="languages"
            :localizations="form.localizations" :translatedLanguages="translatedLanguages" @setOriginalLanguage="setOriginalLanguage" @setPrimaryLanguage="setPrimaryLanguage" @showLocalization="showLocalization"
          />
          <div v-if="form.originalLanguage" class="forms-container" :class="{'visible-localizations': isManagingLocalizations}">
            <form @submit.prevent="onSubmit" novalidate v-for="localization in form.localizations" :key="localization.language">
              <div class="form-content-container" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                <TrekioSwitch v-if="$store.state.currentUserRole === 'ADMIN_SUPERVISOR' && !isManagingLocalizations" v-model="stateSwitch"
                  :activeText="$t('general.publishedFem')" :inactiveText="$t('general.hiddenFem')"
                />
                <TrekioInput id="title" :label="$t('inputLabels.campaignTitle')" v-model="localization.title" :error="error.title" maxLength="100" required
                  :disabled="primaryLanguage == localization.language && isManagingLocalizations"
                />
                <TrekioTextarea id="description" type="textarea" :label="$t('inputLabels.campaignDescription')" v-model="localization.description" :error="error.description" maxLength="160" required
                  :disabled="primaryLanguage == localization.language && isManagingLocalizations" :hint="$t('inputHints.maxLength160')"
                />

                <div v-if="!isManagingLocalizations" class="dates d-flex">
                  <TrekioInput id="validFrom" required :label="$t('inputLabels.campaignValidFrom')" type="date" max="9999-12-31" :error="error.validFrom"
                    v-model="form.validFrom"
                  />
                  <TrekioInput id="validTo" required :label="$t('inputLabels.campaignValidTo')" type="date" max="9999-12-31" :error="error.validTo"
                    v-model="form.validTo"
                  />
                </div>
                <Checkbox v-model="localization.published" @change="onChangePublishLocalization">
                  {{ $t("admin.approveCampaignLocalization")}}
                </Checkbox >
              </div>
            </form>
          </div>
        </b-collapse>
        <a v-if="form.originalLanguage && !isManagingLocalizations" class="basic-info" v-b-toggle.collapseTravelTips>{{ $t("general.travelTips") }} ({{ travelTipIds && travelTipIds.length }})</a>
        <b-collapse v-if="form.originalLanguage && !isManagingLocalizations" id="collapseTravelTips" visible>
          <TravelTipList style="padding-top: 30px;" @updateTopDate="updateTopDate" :travel-tips="campaignTravelTips || newCampaignTravelTips" :manageCampaignTips="true"
          @addTravelTipModal="addTravelTipModal" @deleteTravelTip="deleteTravelTip" :showOldTips="true" :savedTravelTips="campaign && campaign.travelTips" :campaignLocalizationLanguages="form.localizations.map(loc => loc.language)"
          />
        </b-collapse>
      <div class="fixed-buttons" :class="{'manage-localization': isManagingLocalizations, 'full-width': !isSidebarVisible}">
        <div>
          <div v-if="$route.params.campaignId && !isManagingLocalizations">
            <TrekioButton secondary @click="$bvModal.show('campaign-delete-modal')">{{ $t("buttons.delete") }}</TrekioButton>
          </div>
          <div v-if="form.originalLanguage">
            <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" @click.prevent="onSubmit" primary>{{ isManagingLocalizations ? $t('buttons.saveTranslations') : $t('buttons.save') }}</TrekioButton>
          </div>
        </div>
      </div>
    <b-modal class="modal" id="campaign-delete-modal" hide-footer hide-header>
        <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
          <h3 class="moda-title pb-3">{{ $t("modals.campaignDeleteTitle") }}</h3>
            <p class="modal-text pb-5">{{ $t("modals.campaignDeleteText") }}</p>
            <div class="pb-5 d-flex" style="gap: 40px">
                <TrekioButton secondary @click="$bvModal.hide('campaign-delete-modal')">{{$t("general.back")}}</TrekioButton>
                <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.delete") }}</TrekioButton>
            </div>
        </div>
    </b-modal>
    <b-modal class="modal" id="add-travelTip-modal" hide-footer hide-header>
        <div class="x close-dropdown" @click="$bvModal.hide('add-travelTip-modal')"></div>
        <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
          <div v-if="$apollo.queries.travelTips.loading" style="position: absolute; left: 50px">{{ $t("loading.loading") }}</div>
          <div v-else-if="!travelTipsToAdd || travelTipsToAdd.length == 0" style="position: absolute; left: 50px">{{ $t("admin.noTravelTipWithoutCampaign") }}</div>
          <TravelTipList :travel-tips="travelTipsToAdd" :addTravelTipsToCampaign="true" @addTravelTipConfirmed="addTravelTipConfirmed" :showOldTips="true" :campaignLocalizationLanguages="form.localizations.map(loc => loc.language)"/>
        </div>
    </b-modal>
    </div>
  </div>
</template>

<script>
import {CAMPAIGN_BY_ID_ADMIN} from "@/api/graphql/query/CampaignQuery";
import CampaignService from "@/services/CampaignService";
import {TRAVEL_TIPS} from "@/api/graphql/query/TravelTipQuery";
import TravelTipList from "@/components/admin/TravelTipList";
import TravelTipService from "../../services/TravelTipService";
import LocalizationSelects from '@/components/admin/LocalizationSelects';
import UnsavedChangesModal from '@/components/admin/UnsavedChangesModal';
import Checkbox from '@/components/Checkbox'
import UnpublishOtherLocalizationsModal from '@/components/admin/UnpublishOtherLocalizationsModal'
import sharedUtils from "../../utils/sharedUtils";

export default {
  name: "ManageCampaignDlg",
  components: {
    TravelTipList,
    LocalizationSelects,
    UnsavedChangesModal,
    Checkbox,
    UnpublishOtherLocalizationsModal
  },

  data: function() {
      return {
          isUnpublishOtherLocalizationsModalVisible: false,
          isUnsavedChangesModalVisible: false,
          modalContinueAction: null,
          primaryLanguage: null,
          isManagingLocalizations: false,
          editedLanguage: null,
          initialLoading: true,
          afterFirstLoad: false,
          leaveOnCreateOrDelete: false,
          loading: '',
          loadingDelete: '',
          stateSwitch: null,
          lastSavedForm: null,
          form: {
              validFrom: null,
              validTo: null,
              state: '',
              localizations: [],
              originalLanguage: null,
          },
          error: {
            title: '',
            description: '',
            validFrom: '',
            validTo: ''
          },
          campaignTravelTips: [],
          travelTips: [],
          travelTipIds: [],
          travelTipIdsToUntop: [],
          show: true,
          campaignId: null,
          alert: {
              dismissSecs: 6,
              dismissCountDown: 0,
              showDismissibleAlert: false,
              notification: ''
          },
          alertDanger: {
              dismissSecs: 999,
              dismissCountDown: 0,
              showDismissibleAlert: false,
              notification: ''
          },
      }
  },
  beforeRouteLeave (to, from , next) {
    let question = ''
    if (!this.campaign || this.campaign.travelTips.map(tip => tip.id).sort((a, b) => a - b).join(',') != this.travelTipIds.sort((a, b) => a - b).join(',')
        || this.changeInForm()) {
      question = this.$t("admin.unsavedChangesWarning")
    }

    if (this.leaveOnDelete == true) {
      next()
      return
    }

    if (question) {
      const answer = window.confirm(question)
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
    else {
      next()
    }
  },

  computed: {
    preferredLanguage() {
      return this.$store.state.preferredLanguage
    },
    languages() {
      return this.$store.state.languages;
    },
    translatedLanguages() {
      return this.form.localizations.map(localization => localization.language).filter(lang => {
        return lang != this.primaryLanguage
      })
    },
    languagesToBeTranslated() {
      const usedLanguageCodes = this.form.localizations.map(localization => localization.language);
      return this.languages.filter(language => !usedLanguageCodes.includes(language.code));
    },
    travelTipsToAdd() {
      if (!this.travelTips) return
      return this.travelTips.filter(travelTip => {
        if (['IN_PROGRESS', 'TO_REWORK', 'DECLINED'].includes(travelTip.state)) return false
        if (!travelTip.campaign) return this.travelTipIds.includes(travelTip.id.toString()) ? false : true
        if (travelTip.campaign.id == this.campaignId && (!this.travelTipIds.includes(travelTip.id.toString()))) return true
        if (travelTip.campaign.id) return false
        return true
      })
    },
    newCampaignTravelTips() {
      return this.travelTips.filter(tip => this.travelTipIds.includes(tip.id.toString()))
    },
    isSidebarVisible() {
      return this.$store.getters.getIsSidebarVisible
    }
  },

  methods: {
    showTranslationOptionsModal() {
      if (this.isChangeInForm(() => this.showTranslationOptionsModal())) return
      this.$bvModal.show('create-language-translation-modal')
    },
    onChangePublishLocalization(evt) {
      if (evt.target.checked) {
        this.validateLocalizationInputs()
      }
    },
    createCopyOfForm(form) {
      return {
        ...form,
        localizations: form.localizations.map(loc => {
          return {...loc}
        })
      }
    },
    discardChangesAndContinue() {
      this.form = this.createCopyOfForm(this.lastSavedForm)
      this.sortLocalizations()
      this.isUnsavedChangesModalVisible = false
      this.removeLocalizationInputErrors()
      this.removeFormErrors()
      this.modalContinueAction()
    },
    isChangeInForm(callback) {
      const hasFormChanged = JSON.stringify(this.form) != JSON.stringify(this.lastSavedForm)
      if (hasFormChanged) {
        console.log("has changed", this.form, this.lastSavedForm)
        this.modalContinueAction = callback
        this.isUnsavedChangesModalVisible = true
        return true
      }
      
      return false
    },
    changeInForm() {
      return JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)
    },
    createLocalization(languageCode) {
      this.form.localizations.push({
        language: languageCode,
        title: '',
        description: '',
        published: true
      })
    },
    setOriginalLanguage(languageCode) {
      this.form.originalLanguage = languageCode
      this.primaryLanguage = languageCode
      this.createLocalization(languageCode)
    },
    setPrimaryLanguage(languageCode) {
      if (this.primaryLanguage == languageCode) return
      if (this.isChangeInForm(() => this.setPrimaryLanguage(languageCode))) return
      this.primaryLanguage = languageCode
      if (this.editedLanguage == languageCode) {
        this.editedLanguage = null
      }
      this.sortLocalizations()
    },
    findLanguage(languageCode) {
      return this.languages.find(lang => lang.code == languageCode)
    },
    createTranslation(language) {
      this.createLocalization(language.code)
      this.showLocalization(language.code, true)
      this.isManagingLocalizations = true
      this.$bvModal.hide('create-language-translation-modal')
    },
    showLocalization(languageCode, isCreated = false) {
      if (this.editedLanguage == languageCode) return
      if (!isCreated) {
        if (this.isChangeInForm(() => this.showLocalization(languageCode, isCreated))) return
      }
      this.removeLocalizationInputErrors()
      this.editedLanguage = languageCode
      this.sortLocalizations()
    },
    sortLocalizations(){
      sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
      if (this.lastSavedForm) {
        sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
      }
    },
    toggleManageLocalizations() {
      if (this.isChangeInForm(this.toggleManageLocalizations)) return
      // this.removeLocalizationInputErrors(this.isManagingLocalizations)
      this.isManagingLocalizations = !this.isManagingLocalizations
    },
    async updateTopDate(id) {
      await this.$apollo.queries.campaign.refetch({
        campaignId: this.campaignId,
      });
      const indexOriginalTips = this.campaign.travelTips.findIndex(tip => tip.id == id)
      const date = '' + this.campaign.travelTips[indexOriginalTips].topDate

      const index = this.campaignTravelTips.findIndex(tip => tip.id == id)
      if (index != -1) {this.campaignTravelTips[index].topDate = date}
    },

    deleteTravelTip(id) {
      if (!this.travelTipIdsToUntop.includes(id)) {this.travelTipIdsToUntop = [...this.travelTipIdsToUntop, id]}
        const index = this.travelTipIds.indexOf(id.toString())
        if (index > -1) { this.travelTipIds.splice(index, 1) }
        this.campaignTravelTips = this.campaignTravelTips.filter(travelTip => travelTip.id != id)
    },

    addTravelTipConfirmed(travelTip) {
      let travelTipId= "" + travelTip.id
      if (this.travelTipIdsToUntop.includes(travelTip.id)) {this.travelTipIdsToUntop = this.travelTipIdsToUntop.filter(tipId => tipId != travelTip.id)}
      if (this.travelTipIds.includes(travelTipId)) return
      this.travelTipIds.push(travelTipId)
      this.campaignTravelTips = [...this.campaignTravelTips, travelTip]

      this.$bvModal.hide('add-travelTip-modal')      
    },
    addTravelTipModal() {
      this.$bvModal.show('add-travelTip-modal')
    },
    removeLocalizationInputErrors() {
      for (const [key, value] of Object.entries(this.form.localizations[0])) {
        if (key != 'language' || key != 'published') {
          this.error[key] = '';
        }
      }
    },
    removeFormErrors() {
      this.error.validFrom = '',
      this.error.validTo = ''
    },
    validateLocalizationInputs() {
      const localizations = this.isManagingLocalizations ? this.form.localizations[1] : this.form.localizations[0]
      if (localizations) {
          let errorCount = 0
          const { title, description } = localizations

          if (!title) {
            errorCount += 1
            this.error.title = this.$t("inputErrors.required")
          }
          if (!description) {
            errorCount += 1
            this.error.description = this.$t("inputErrors.required")
          }
          if (errorCount > 0) {
            this.form.localizations[this.isManagingLocalizations ? 1 : 0].published = false
          }

          return errorCount
      }

    },
    validateInputs() {
        let errorCount = 0
        this.removeLocalizationInputErrors()
        this.removeFormErrors()

        if (!this.form.validFrom) {
          errorCount += 1
          this.error.validFrom = this.$t("inputErrors.required")
        }
        if (!this.form.validTo) {
          errorCount += 1
          this.error.validTo = this.$t("inputErrors.required")
        }
        const ammountOfLocalizationInputErrors = this.validateLocalizationInputs()
        errorCount += ammountOfLocalizationInputErrors

        if (!this.validateToDate()) errorCount += 1

        if (errorCount > 0) {
            this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
            return false
        }

        return true
    },
    isChangeInLocalizationInputs() {
      let isAnyLocalizationPublished = false
      
      for (let i = 1; i < this.form.localizations.length; i++) {
        if (this.form.localizations[i].published) {
          isAnyLocalizationPublished = true
        }
      }
      if (!isAnyLocalizationPublished) return

      if (JSON.stringify(this.form.localizations[0]) != JSON.stringify(this.lastSavedForm.localizations[0])) {
        this.isUnpublishOtherLocalizationsModalVisible = true
        return true
      }
      return false
      
    },
    saveAndUnpublishOthers() {
      this.isUnpublishOtherLocalizationsModalVisible = false
      this.unpublishOtherLocalizations()
      this.onSubmit(true)
    },
    unpublishOtherLocalizations() {
      const localizationLanguage = this.form.localizations[0].language
      this.form.localizations.forEach(loc => {
        if (localizationLanguage != loc.language) {
          loc.published = false
        }
      })
    },
    async onCreate(id) {
      this.$router.replace({ params: { campaignId: id } })
      this.campaignId = id
      this.resetToDefault()
    },
    onSubmit: async function(isModalAction = false) {
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
      if (!this.isManagingLocalizations && !isModalAction && this.isChangeInLocalizationInputs()) return
      if (this.isUnpublishOtherLocalizationsModalVisible) this.isUnpublishOtherLocalizationsModalVisible = false

      if (!this.isManagingLocalizations && !this.validateInputs()) return
        if(!this.$route.params.campaignId) {
          this.loading = this.$t("loading.campaignCreate")
          await CampaignService.createCampaign(this.form, this.travelTipIds)
            .then( async ({data}) => {
              this.initialLoading = false
              this.showAlert( this.$t('alerts.campaignCreated'))
              this.onCreate(data.createCampaign.id)
            })
            .catch(err => {
              console.log(err)
              this.showAlertDanger(this.$t('alerts.campaignCreateError'))
            })
        } else {
          this.loading = this.$t("loading.campaignUpdate")
            await CampaignService.updateCampaign(this.campaignId, this.form, this.travelTipIds)
              .then(async () => {
                await this.$apollo.queries.campaign.refetch({
                    campaignId: this.campaignId,
                });
                if (this.travelTipIdsToUntop.length > 0) {
                  for (let tipId of this.travelTipIdsToUntop) {
                    await TravelTipService.untopTravelTip(tipId)
                  }
                  // this.$apollo.queries.travelTips.refetch();
                  this.travelTipIdsToUntop = []
                }
                this.showAlert(this.$t('alerts.campaignUpdated'))
              })
              .catch((err) => {
                console.log(err)
                this.showAlertDanger(this.$t('alerts.campaignUpdateError'))
              })
            window.scrollTo({top: 0, behavior: "smooth"})
          }
    },
    onDeleteConfirmed: async function() {
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
      this.loadingDelete = this.$t("loading.campaignDelete")
      await CampaignService.deleteCampaign(this.campaignId)
        .then(async () => {
          if (this.campaign.travelTips.length > 0) {
            for (let travelTip of this.campaign.travelTips) {
              await TravelTipService.untopTravelTip(travelTip.id)
            }
          }
          this.leaveOnDelete = true
          this.$emit("showAlert", this.$t('alerts.campaignDeleted'))
          await this.$router.push({name: 'adminCampaignList'});
        })
        .catch(err => {
          console.log(err)
          this.showAlertDanger(this.$t('alerts.campaignDeleteError'))
        })
    },

    resetToDefault: function() {
      let moment = require('moment');
      if(this.campaign) {
        this.stateSwitch = this.campaign.state === "PUBLISHED" ? true : false
        this.form = {
          validFrom: moment(this.campaign.validFrom).format("YYYY-MM-DD"),
          validTo: moment(this.campaign.validTo).format("YYYY-MM-DD"),
          state: this.campaign.state,
          originalLanguage: this.campaign.originalLanguage ? this.campaign.originalLanguage : 'cs',
          localizations: this.campaign.localizations.map(localization => {
            const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
            delete localizationCopy['__typename'];
            return localizationCopy
          })
        }

        this.lastSavedForm = this.createCopyOfForm(this.form)

        if (!this.primaryLanguage) {
          const campaignLocalizationLanguages = this.form.localizations.map(loc => loc.language);
          if (campaignLocalizationLanguages.includes(this.preferredLanguage)) {
            this.primaryLanguage = this.preferredLanguage
          } else if (this.preferredLanguage == 'cs' && campaignLocalizationLanguages.includes('sk')) {
            this.primaryLanguage = 'sk'
          } else {
            this.primaryLanguage = this.form.originalLanguage
          }
        }

        // Make sure the primary language (original or chosen language) form will be on the left side when editing translations
        this.sortLocalizations()

        this.visibleLocalizations = [this.form.originalLanguage]
        this.campaignTravelTips = JSON.parse(JSON.stringify(this.campaign.travelTips))
        this.travelTipIds = this.campaign.travelTips.map(tip => tip.id.toString())
      } else {
        this.stateSwitch = this.$store.state.currentUserRole === 'ADMIN_SUPERVISOR'
        this.form.state = this.$store.state.currentUserRole === 'ADMIN_SUPERVISOR' ? "PUBLISHED" : "NOT_PUBLISHED"
      }
    },

    showAlert(notification) {
      this.loading = ''
      this.alert.dismissCountDown = this.alert.dismissSecs;
      this.alert.notification = notification;
    },
    showAlertDanger(notification) {
      this.loading = this.loadingDelete = ''
      this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
      this.alertDanger.notification = notification;
    },
    countDownChangedDanger: function(dismissCountDown) {
      this.alertDanger.dismissCountDown = dismissCountDown;
    },

    validateToDate: function(){
      if (this.form.validFrom != null && this.form.validFrom > this.form.validTo) {
        this.error.validTo = this.$t("inputErrors.validToBeforeValidFrom")
        return false;
      }
      return true;
    },
  },

  apollo: {
    campaign: {
      query: CAMPAIGN_BY_ID_ADMIN,
      variables: function() {
        return {
            campaignId: this.campaignId,
        }
      },
      skip: function () {
        return this.campaignId == null;
      },
    },
    travelTips: {
      query: TRAVEL_TIPS
    }
  },

  watch: {
    'campaign'() {
      if (this.initialLoading) this.initialLoading = false
      this.resetToDefault()
      if (!this.afterFirstLoad) {
        this.$apollo.queries.travelTips.refetch();
        this.afterFirstLoad = true
      }
    },
    stateSwitch(nV) {
      this.form.state = nV == true ? "PUBLISHED" : "NOT_PUBLISHED"
    },
  },

  created: function() {
    this.campaignId = this.$route.params.campaignId;
    this.resetToDefault()
  }
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.admin-page-loading {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: calc(50% + 140px);
  font-size: 20px;
}

#campaign-description {
  height: 130px !important;
}

.buttons-localizations {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  gap: 20px;
  width: 100%
}


.language-option {
  height: 60px;
  width: 200px;
  padding: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $tr-green;
  }
}

.forms-container {
  gap: 30px;
  padding-bottom: 40px;

  form {
    width: 100%;
  }

  &.visible-localizations {
    display: flex;
    flex-wrap: wrap;

    form {
      width: calc(50% - 15px);
    }
  }
  .form-content-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.basic-info, .travel-tips {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  color: $tr-black;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: dotted 3px $tr-light-gray;
  border-top: dotted 3px $tr-light-gray;
  height: 80px;
  cursor: pointer;

  &:hover{
    color: $tr-black;
    text-decoration: none;
  }

  &::before{
    content: "";
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='17' viewBox='0 0 24 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.5L10.5 14L22.5 2' stroke='%233BEA7E' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 24px 17px;
  }

  &::after{
    content: "";
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    background-size: 24px 15px;
    margin-left: auto;
  }

  &[aria-expanded="true"]{
    &::after{
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
    }
  }
}

  .close-dropdown {
    background-color: transparent;
    position: absolute;
    right: 8px;
    top: 8px;
    
    &::before, &::after {
      background-color: $tr-black;
    }

  }

  .dates {
    gap: 40px;
  }

  .fixed-buttons {
    display: flex;
    justify-content: center;
    max-width: calc(100% - 300px);
    width: 100%;
    bottom: 0;
    left: 300px;
    background-color: $tr-white;
    border-top: 1px solid $tr-light-gray;;
    position: fixed;
    z-index: 1030;
    transition: 0.3s ease-in-out;
    transition-property: left, max-width;

    &.full-width {
      max-width: 100%;
      left: 0
    }

    & > div {
      max-width: 1090px;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 40px;
      padding: 10px 40px;

      & > div {
        max-width: 330px;
        width: 100%;
      }
    }

    &.manage-localization {
      & > div {
        justify-content: unset;

        & > div {
          margin-left: calc(50% + 15px);
        }
      }
    }
  }

</style>

<style lang="scss">
    @import '@/scss/variables';

  #add-travelTip-modal___BV_modal_body_ {
    background-color: $tr-white;
  }
</style>
