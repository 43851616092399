<template>
    <b-modal class="modal" v-model="isModalVisible" hide-footer hide-header no-close-on-backdrop>
        <div class="unsaved-changes-modal-container">
            <h3 class="pb-3">{{ $t("modals.unsavedChangesInFormTitle") }}</h3>
            <h5 class="pb-3">{{ $t("modals.unsavedChangesInFormText") }}</h5>
            <div class="buttons-container">
                <TrekioButton secondary @click="$emit('hideModal')">{{$t("general.back")}}</TrekioButton>
                <TrekioButton primary @click="$emit('discardChangesAndContinue')">{{ $t('buttons.discardChangesAndContinue') }}</TrekioButton>
            </div>
        </div>
    </b-modal>
</template>

<script>

    export default {
        props: {
            isModalVisible: {
                type: Boolean,
                default: false
            }
        }
    }

</script>

<style lang="scss" scoped>
    .unsaved-changes-modal-container {
        padding-inline: 30px;
        padding-block: 30px;

        .buttons-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 40px;
            row-gap: 20px;
        }
    }

</style>