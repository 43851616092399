<template>
    <div class="d-flex justify-content-between align-items-center row mx-0 w-100">
        <div class="col-6 px-0">
            <TrekioSelect ref="select-original-language" v-if="!originalLanguage && !isManagingLocalizations" :whiteBg="whiteBg" :label="$t('inputLabels.originalLanguage')" :placeholder="$t('inputLabels.chooseOriginalLanguage')">
                <li v-for="language in languages" :key="language.code">
                    <button @click="setOriginalLanguage(language.code)">{{ language.code }}</button>
                </li>
            </TrekioSelect>
            <TrekioSelect ref="select-primary-language" v-else :whiteBg="whiteBg" :label="$t('inputLabels.choosePrimaryLanguage')" :placeholder="$t('inputLabels.chooseOriginalLanguage')"
                :text="primaryLanguage == originalLanguage ? primaryLanguage + ` (${$t('admin.original')})` : primaryLanguage"
             >
                <li v-for="localization in sortedLocalizations" :key="localization.language">
                    <button @click="setPrimaryLanguage(localization.language)">
                        {{ localization.language == originalLanguage ? localization.language + ` (${$t('admin.original')}) ` : 
                            localization.language + (isManagePicturePage ? '' :
                                (localizations.find(loc => loc.language == localization.language).published ?
                                    ` (${$t("admin.published")})` :
                                    ` (${$t("admin.notPublished")})`)
                            )
                        }}
                    </button>
                </li>
            </TrekioSelect>
        </div>
        <div class="col-6">
            <TrekioSelect ref="select-edited-language" v-if="isManagingLocalizations" :whiteBg="whiteBg" :label="$t('inputLabels.showTranslation')" :placeholder="$t('inputLabels.chooseOriginalLanguage')"
                :text="editedLanguage ? editedLanguage : $t('admin.notSelected')"
            >
                <li>
                    <button @click="showLocalization(null)">{{ $t("admin.notSelected") }}</button>
                </li>
                <li v-for="language in sortedTranslatedLanguages" :key="language">
                    <button @click="showLocalization(language)" :disabled="language == originalLanguage">{{ language == originalLanguage ? language + ` (${$t('admin.original')}) ` : language }}</button>
                </li>
            </TrekioSelect>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isManagingLocalizations: {
                required: true,
                type: Boolean
            },
            primaryLanguage: {
                type: String
            },
            originalLanguage: {
                type: String
            },
            editedLanguage: {
                type: String
            },
            languages: {
                type: Array,
            },
            localizations: {
                type: Array,
            },
            translatedLanguages: {
                type: Array
            },
            whiteBg: {
                type: Boolean,
                default: false,
            },
            isManagePicturePage: {
                type: Boolean,
                default: false
            }
        },

        emits: ['setOriginalLanguage', 'setPrimaryLanguage', 'showLocalization'],

        methods: {
            setOriginalLanguage(language) {
                this.$emit('setOriginalLanguage', language)
                this.$refs['select-original-language'].closeDropdown()
            },

            setPrimaryLanguage(language) {
                this.$emit('setPrimaryLanguage', language)
                this.$refs['select-primary-language'].closeDropdown()
            },

            showLocalization(language) {
                this.$emit('showLocalization', language)
                this.$refs['select-edited-language'].closeDropdown()
            }
        },

        computed: {
            sortedLocalizations() {
                if (!this.localizations) return
                return [...this.localizations].sort((a, b) => a.language.localeCompare(b.language))
            },
            sortedTranslatedLanguages() {
                if (!this.translatedLanguages) return
                return [...this.translatedLanguages].sort((a, b) => a.localeCompare(b))
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    li button {
        border: none !important;
        text-align: left;
        padding-inline: 20px;
        border-radius: 0;
        outline: none;
        
        &:focus-visible {
            background-color: $tr-green;
            color: $tr-white;
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
</style>